import { getConfig } from "@src/config";
import { GQLFinanceType } from "@src/graphql.apollo.generated";
import { useIntl } from "react-intl";

import { ReactSelectOptions } from "../../../types";
import { IFilter } from "./IFilter";
import useSearchFilter from "./useSearchFilter";

export const useFinanceTypeOptions = () => {
    const scope = getConfig("scope");
    const intl = useIntl();

    const financeTypeOptions: ReactSelectOptions<GQLFinanceType>[] = [];

    if (scope.domain !== "car4me.porschefinance.hu") {
        financeTypeOptions.push({
            value: GQLFinanceType.leasing,
            label: intl.formatMessage({
                id: "leaseme.leasing",
                defaultMessage: "Leasing",
            }),
        });
        if (getConfig("hasCredit")) {
            financeTypeOptions.push({
                value: GQLFinanceType.credit,
                label: intl.formatMessage({
                    id: "leaseme.credit",
                    defaultMessage: "Kredit",
                }),
            });
        }
    } else {
        financeTypeOptions.push(
            {
                value: GQLFinanceType.openEndLeasing,
                label: intl.formatMessage({
                    id: "leaseme.openEndLeasing",
                    defaultMessage: "Open-End-Leasing",
                }),
            },
            {
                value: GQLFinanceType.closedEndLeasing,
                label: intl.formatMessage({
                    id: "leaseme.closedEndLeasing",
                    defaultMessage: "Closed-End-Leasing",
                }),
            },
        );
    }

    return financeTypeOptions;
};

//used for AT and RO
const financeRuntimeValuesDefault = [
    {
        value: 24,
    },
    {
        value: 36,
    },
    {
        value: 48,
    },
    {
        value: 60,
    },
];

const financeRuntimeValuesBG = [
    {
        value: 12,
    },
    {
        value: 24,
    },
    {
        value: 36,
    },
    {
        value: 48,
    },
    {
        value: 60,
    },
];

const financeRuntimeValuesSI = [
    {
        value: 12,
    },
    {
        value: 24,
    },
    {
        value: 36,
    },
    {
        value: 48,
    },
    {
        value: 60,
    },
    {
        value: 72,
    },
    {
        value: 84,
    },
    {
        value: 96,
    },
];

const financeRuntimeValuesOflHU = [
    {
        value: 24,
    },
    {
        value: 36,
    },
    {
        value: 48,
    },
    {
        value: 60,
    },
    {
        value: 72,
    },
    {
        value: 84,
    },
];

const financeRuntimeValuesCflHU = [
    {
        value: 12,
    },
    {
        value: 24,
    },
    {
        value: 36,
    },
    {
        value: 48,
    },
    {
        value: 60,
    },
];

export function getFinanceRuntimeValues(domain: string, filter: IFilter) {
    if (domain === "car4me.bg") {
        return financeRuntimeValuesBG;
    } else if (domain === "car4me.si") {
        return financeRuntimeValuesSI;
    } else if (domain === "car4me.porschefinance.hu" && filter.financeType === "openEndLeasing") {
        return financeRuntimeValuesOflHU;
    } else if (domain === "car4me.porschefinance.hu" && filter.financeType === "closedEndLeasing") {
        return financeRuntimeValuesCflHU;
    } else {
        return financeRuntimeValuesDefault;
    }
}

export const useFinanceRuntimeOptions = (domain: string) => {
    const intl = useIntl();
    const filter = useSearchFilter();

    const financeRuntimeValues = getFinanceRuntimeValues(domain, filter.filter);

    return financeRuntimeValues.map((runtime) => ({
        value: runtime.value,
        label: intl.formatMessage(
            {
                id: "leaseme.runtime.value",
                defaultMessage: "{financeRuntime} Monate",
            },
            {
                financeRuntime: intl.formatNumber(runtime.value),
            },
        ),
    }));
};

export function getKmPerYearOptions(domain: string) {
    if (domain === "car4me.bg") {
        return kmPerYearOptionsBG;
    } else if (domain === "car4me.si") {
        return kmPerYearOptionsSI;
    } else {
        return kmPerYearOptionsAT;
    }
}

const kmPerYearOptionsAT = [
    {
        value: 10000,
        label: `10.000 km`,
    },
    {
        value: 15000,
        label: `15.000 km`,
    },
    {
        value: 20000,
        label: `20.000 km`,
    },
    {
        value: 25000,
        label: `25.000 km`,
    },
    {
        value: 30000,
        label: `30.000 km`,
    },
    {
        value: 35000,
        label: `35.000 km`,
    },
    {
        value: 40000,
        label: `40.000 km`,
    },
    {
        value: 45000,
        label: `45.000 km`,
    },
    {
        value: 50000,
        label: `50.000 km`,
    },
];

export const kmPerYearOptionsBG = [
    {
        value: 15000,
        label: `15 000 км`,
    },
    {
        value: 25000,
        label: `25 000 км`,
    },
];

const kmPerYearOptionsSI = [
    {
        value: 1,
        label: `1`,
    },
];

export const kmOptionsBG = [
    {
        value: 500,
        label: `500 км`,
    },
    {
        value: 1000,
        label: `1.000 км`,
    },
    {
        value: 3000,
        label: `3.000 км`,
    },
    {
        value: 5000,
        label: `5.000 км`,
    },
    {
        value: 7000,
        label: `7.000 км`,
    },
    {
        value: 10000,
        label: `10.000 км`,
    },
    {
        value: 15000,
        label: `15.000 км`,
    },
    {
        value: 20000,
        label: `20.000 км`,
    },
    {
        value: 25000,
        label: `25.000 км`,
    },
    {
        value: 30000,
        label: `30.000 км`,
    },
    {
        value: 40000,
        label: `40.000 км`,
    },
    {
        value: 50000,
        label: `50.000 км`,
    },
    {
        value: 60000,
        label: `60.000 км`,
    },
    {
        value: 70000,
        label: `70.000 км`,
    },
    {
        value: 80000,
        label: `80.000 км`,
    },
    {
        value: 90000,
        label: `90.000 км`,
    },
    {
        value: 100000,
        label: `100.000 км`,
    },
    {
        value: 125000,
        label: `125.000 км`,
    },
    {
        value: 150000,
        label: `150.000 км`,
    },
    {
        value: 200000,
        label: `200.000 км`,
    },
    {
        value: 300000,
        label: `300.000 км`,
    },
    {
        value: 500000,
        label: `500.000 км`,
    },
];

export const kmOptionsAT = [
    {
        value: 500,
        label: `500 km`,
    },
    {
        value: 1000,
        label: `1.000 km`,
    },
    {
        value: 3000,
        label: `3.000 km`,
    },
    {
        value: 5000,
        label: `5.000 km`,
    },
    {
        value: 7000,
        label: `7.000 km`,
    },
    {
        value: 10000,
        label: `10.000 km`,
    },
    {
        value: 15000,
        label: `15.000 km`,
    },
    {
        value: 20000,
        label: `20.000 km`,
    },
    {
        value: 25000,
        label: `25.000 km`,
    },
    {
        value: 30000,
        label: `30.000 km`,
    },
    {
        value: 40000,
        label: `40.000 km`,
    },
    {
        value: 50000,
        label: `50.000 km`,
    },
    {
        value: 60000,
        label: `60.000 km`,
    },
    {
        value: 70000,
        label: `70.000 km`,
    },
    {
        value: 80000,
        label: `80.000 km`,
    },
    {
        value: 90000,
        label: `90.000 km`,
    },
    {
        value: 100000,
        label: `100.000 km`,
    },
    {
        value: 125000,
        label: `125.000 km`,
    },
    {
        value: 150000,
        label: `150.000 km`,
    },
    {
        value: 200000,
        label: `200.000 km`,
    },
    {
        value: 300000,
        label: `300.000 km`,
    },
    {
        value: 500000,
        label: `500.000 km`,
    },
];

export function getkmOptionsValues(domain: string) {
    if (domain === "car4me.bg") {
        return kmOptionsBG;
    } else {
        return kmOptionsAT;
    }
}

export const kmFilterOptions = (domain: string, minKm: number = 0) => {
    const returnOptions: ReactSelectOptions<number>[] = [];
    getkmOptionsValues(domain).forEach((km) => {
        if (km.value >= minKm) {
            returnOptions.push(km);
        }
    });
    return returnOptions;
};

export const firstRegistrationYearOptions = (minYear: number = 1995, maxYear: number = new Date().getFullYear()) => {
    const firstRegistrationYearOptions: ReactSelectOptions<number>[] = [];
    for (let i = minYear; i <= maxYear; i++) {
        firstRegistrationYearOptions.push({
            value: i,
            label: `${i}`,
        });
    }
    return firstRegistrationYearOptions.reverse();
};

const psOptionsValues = [
    {
        value: 55,
    },
    {
        value: 60,
    },
    {
        value: 70,
    },
    {
        value: 80,
    },
    {
        value: 90,
    },
    {
        value: 100,
    },
    {
        value: 110,
    },
    {
        value: 125,
    },
    {
        value: 140,
    },
    {
        value: 150,
    },
    {
        value: 160,
    },
    {
        value: 175,
    },
    {
        value: 190,
    },
    {
        value: 200,
    },
    {
        value: 220,
    },
    {
        value: 250,
    },
    {
        value: 270,
    },
];

const usePsOptions = () => {
    const intl = useIntl();

    return psOptionsValues.map((psOption) => ({
        value: psOption.value,
        label: intl.formatMessage(
            {
                id: "leaseme.powerValue",
                defaultMessage: "{powerPS} PS",
            },
            {
                powerPS: psOption.value,
            },
        ),
    }));
};

export const psFilterOptions = (minPS: number = 55, maxPS: number = 270) => {
    const returnOptions: { value: number }[] = [];
    psOptionsValues.forEach((km) => {
        if (km.value >= minPS && km.value <= maxPS) {
            returnOptions.push(km);
        }
    });
    return returnOptions;
};

export const usePsFilterOptions = (minPS: number = 55, maxPS: number = 270) => {
    const returnOptions: ReactSelectOptions<number>[] = [];
    const psOptions = usePsOptions();
    psOptions.forEach((km) => {
        if (km.value >= minPS && km.value <= maxPS) {
            returnOptions.push(km);
        }
    });
    return returnOptions;
};

export const pageSizeOptions: ReactSelectOptions<number>[] = [
    {
        value: 12,
        label: "12",
    },
    {
        value: 24,
        label: "24",
    },
    {
        value: 36,
        label: "36",
    },
];
